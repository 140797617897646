
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  background: #FFFFFF;
}

button {
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 2px;
  z-index: 999;
  background: white;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  z-index: 999;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #D3D3D3;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #C0C0C0;
}
